function isUnixTimestamp(value) {
    if (value === null || value === undefined) {
        return false;
    }

    if (!Number.isInteger(Number(value))) {
        return false;
    }

    const date = new Date(Number(value) * 1000);
    return date instanceof Date && !Number.isNaN(date.getTime());
}

export default isUnixTimestamp
